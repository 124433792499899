import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enAU from "./lib/locales/en/translation.json";
import ja from "./lib/locales/ja/translation.json";

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: "en-AU",
    fallbackLng: "en-AU",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      "en-AU": {translation: enAU},
      ja: {translation: ja}
    },
  });

export default i18n;
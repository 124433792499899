import { ReactNode, useContext } from "react";

import { ModalContext } from "../Modal";

import { t } from "i18next";

type ConfirmModalProps = {
  openModalElement: (onClickHandler: () => void) => ReactNode,
  title?: string,
  message? : ReactNode | string,
  confirmButtonText?: string,
  cancelButtonText?: string,
  confirmButtonVariant?: "primary" | "secondary" | "success" | "danger" | "warning"
  size?: "sm" | "md" | "lg",
  handleClose?: () => void,
  handleConfirm?: () => void
}

export default function ConfirmModal({
  openModalElement,
  title = "Warning",
  message = "Are you sure?",
  confirmButtonText = t("core.actions.confirm"),
  cancelButtonText = t("core.actions.cancel"),
  confirmButtonVariant = "primary",
  size = "lg",
  handleClose,
  handleConfirm,
}: ConfirmModalProps) {  

  const OpenModalElementWithOnClick = ({openModalElement}: Pick<ConfirmModalProps, "openModalElement">) => {
    const { handleModal } = useContext(ModalContext);

    return (
      <>
        {
          openModalElement(() => handleModal({
            content: message,
            title: title,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            confirmButtonVariant: confirmButtonVariant,
            size: size,
            handleCancel: handleClose,
            handleConfirm: handleConfirm,
          }))
        }
      </>
    );
  };
  
  return (
    <div id="ConfirmModal">
      <OpenModalElementWithOnClick openModalElement={openModalElement} />
    </div>
  );
}
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
  RowSelectionState,
  OnChangeFn,
  Row
} from "@tanstack/react-table";

import { ApolloError } from "@apollo/client";

import Skeleton from "../Skeleton";

import "./table.css";

type PageInfo = {
  endCursor: string | null
  hasNextPage: boolean
  hasPreviousPage: boolean
  startCursor: string | null
}

type TableProps = {
  className: string;
  isLoading: boolean;
  error: ApolloError | boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Array<ColumnDef<any, unknown>>;
  data: object[];
  noDataText: string;
  darkMode: boolean;
  pageInfo?: PageInfo;
  totalCount?: number | undefined;
  maxCellWidth?: number;
  breakpointResponsiveAt?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "all"
  rowSelection?: RowSelectionState
  setRowSelection?: OnChangeFn<RowSelectionState>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRowId?: ((originalRow: any, index: number, parent?: Row<any> | undefined) => string) | undefined;
}

export default function Table({
  className,
  isLoading,
  error,
  columns,
  data,
  noDataText,
  darkMode,
  maxCellWidth,
  breakpointResponsiveAt = "lg",
  rowSelection,
  setRowSelection = () => {},
  getRowId,
}: TableProps) {

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    ...((getRowId != null) && { getRowId }),
    autoResetPageIndex: false,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <div>
      <div className="h-2" />
      <div className={breakpointResponsiveAt === "all" ? "table-responsive" : `table-responsive-${breakpointResponsiveAt}`}>
        <table className={`${className} table-styling ${darkMode ? "table-styling-dark" : ""}`} style={{fontSize: `${window.innerWidth > 2000 ? "20px" : "15px"}`}}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th className={header.id === "select" ? "text-center" : ""} key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                        </>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            { ((typeof error !== "boolean" && error != null) || error === true)
              ? <tr>
                <td colSpan={table.getHeaderGroups()[0].headers.length} className="text-danger" style={{verticalAlign: "middle"}}>
                  There was an error fetching data, please try again.
                </td>
              </tr>
              : data.length === 0 && isLoading
                ? <tr>
                  { table.getHeaderGroups()[0].headers.map(header => {
                    return (
                      <td key={`${header.id}-loading`} style={{verticalAlign: "middle"}}>
                        <Skeleton width="100%" height="20px" />
                      </td>
                    );
                  })}
                </tr>
                : table.getRowModel().rows.length > 0
                  ? table
                    .getRowModel()
                    .rows
                    .map(row => {
                      return (
                        <tr key={row.id}>
                          {row.getVisibleCells().map(cell => {
                            return (
                              <td key={cell.id} style={{verticalAlign: "middle", maxWidth: (maxCellWidth != null) ? `${maxCellWidth}px` : "auto"}}>
                                {
                                  flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )
                                }
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  : <tr><td colSpan={table.getHeaderGroups()[0].headers.length}>
                    {noDataText ?? "No data to display"}
                  </td></tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  );
}